import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
  slug,
  date,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <div className="content">
      {helmet || ""}
      <h2 className="entry-title">{title}</h2>
      <div className="entry-meta">
        Posted on <Link to={slug}>{date}</Link>
      </div>
      <p>{description}</p>
      <PostContent content={content} />
      {tags && tags.length ? (
        <div className="entry-meta">
          This entry was posted in{" "}
          {tags.map((tag, index) => (
            <span>
              <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
              {index === tags.length - 2 ? " and " : ""}
              {index < tags.length - 2 ? ", " : ""}
            </span>
          ))}
          {". "}
          Bookmark the <Link to={slug}>permalink</Link>.
        </div>
      ) : null}
    </div>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  slug: PropTypes.string,
  date: PropTypes.string,
};

const PostNavigation = ({ prevPost, nextPost }) => (
  <div class="postNavigation">
    {prevPost && (
      <Link className="prev-post" to={prevPost.fields.slug}>
        ← {prevPost.frontmatter.title}
      </Link>
    )}
    {nextPost && (
      <Link className="next-post" to={nextPost.fields.slug}>
        {nextPost.frontmatter.title} →
      </Link>
    )}
  </div>
);

const BlogPost = ({ data }) => {
  const { markdownRemark: post, prevPost, nextPost } = data;

  return (
    <Layout>
      <PostNavigation prevPost={prevPost} nextPost={nextPost} />
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Flag in Distress">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        slug={post.fields.slug}
        date={post.frontmatter.date}
      />
      <PostNavigation prevPost={prevPost} nextPost={nextPost} />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
    prevPost: PropTypes.object,
    nextPost: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!, $prevPostId: String, $nextPostId: String) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        tags
      }
    }
    prevPost: markdownRemark(id: { eq: $prevPostId }) {
      ...NavigationPost
    }
    nextPost: markdownRemark(id: { eq: $nextPostId }) {
      ...NavigationPost
    }
  }

  fragment NavigationPost on MarkdownRemark {
    id
    fields {
      slug
    }
    frontmatter {
      title
    }
  }
`;
